.payment-info {
  .payment-logos {
    display: flex;
    margin-bottom: 40px;
    div {
      margin: 0 5px;
      padding: 0 5px;
      border-left: 1px solid;
      img {
        height: 15px;
        width: auto;
      }
      &:first-child {
        border-left: none;
      }
    }
  }
  .lehNYk {
    min-width: 48%;
    margin-bottom: 40px;
  }
  .group-input-label {
    margin-top: 0 !important;
  }
  #field-wrapper {
    background: transparent;
    border-radius: var(--input-border-radius);
    border: 1px solid var(--border-color-dark);
    height: 40px;
    input {
      background: inherit;
    }
  }
  .group-input-label {
    margin-top: 30px;
  }
  .order-details {
    width: 70%;
    margin: 0 auto;
    border: 2px solid var(--modal-border-dark);
    border-radius: var(--modal-border-radius);
    padding: var(--modal-padding);
    padding-top: 0;
    .page-title {
      margin: 25px auto;
    }
    .total-row-wrapper {
      display: flex;
      justify-content: space-between;
      .total-row {
        display: flex;
        width: 45%;
        align-items: center;
        margin-bottom: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--disabled-input-border-color);
        span {
          width: 115px;
          margin-left: auto;
          display: inline-block;
          border-bottom: 1px solid #000000;
          text-align: center;
        }
        &.total-cost {
          span {
            color: var(--text-color-danger) !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .payment-info {
    .payment-logos {
      display: none;
    }
    .group-input-label {
      text-align: center;
      display: block;
    }
    .lehNYk {
      margin-bottom: 20px;
    }
    .radio-block {
      justify-content: space-between;
    }
    .radio-field {
      display: block;
      text-align: center;
      max-width: 111px;
      input {
        margin: 0;
        padding: 0;
        min-height: 0;
      }
    }
    .order-details {
      width: 100%;
      .total-row-wrapper {
        display: block;
        .total-row {
          width: 100%;
          margin-bottom: 10px;
          padding-bottom: 5px;
        }
      }
    }
  }
}
