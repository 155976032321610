.contact-us-section {
  padding-bottom: 50px;
  .working-hours {
    text-align: center;
    p {
      margin-bottom: 10px;
      color: var(--text-color-dark);
      font-weight: bold;
      a {
        color: var(--text-color-dark);
        text-decoration: none;
      }
    }
  }
}
