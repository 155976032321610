.registration-details {
  .radio-block {
    border-bottom: 1px solid var(--disabled-input-color);
    margin-bottom: 20px;
  }
  .checkbox-block {
    border-bottom: 1px solid var(--disabled-input-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    .checkbox-wrapper {
      width: 35%;
      justify-content: space-between;
      display: inline-block;
      margin-top: 20px;
      &:nth-child(2n) {
        margin-left: 30%;
      }
    }
  }
  .horizontal-radio-block {
    .radio-field {
      input, label {
        margin-bottom: 0;
      }
    }
  }
  .table-block{
    display: flex;
    .table {
      border-spacing: 0;
      text-align: left;
      width: 60%;
      td {
        border-bottom: 1px solid var(--border-color-dark);
        padding: 15px;
        margin: 0;
        width: 100px;
        &:first-child {
          padding-left: 0;
          width:calc(100% - 200px);
        }
        .input-field {
          width: 100% !important;
          margin-bottom: 0 !important;
          input {
            margin-bottom: 0;
            text-align: center;
          }
        }
      }
    }
    .order-details {
      width: 40%;
      margin-left: 2%;
      border: 2px solid var(--modal-border-dark);
      border-radius: var(--modal-border-radius);
      padding: var(--modal-padding);
      padding-top: 0;
      .page-title {
        margin: 25px auto;
      }
      .total-row {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--disabled-input-border-color);
        .contact-us-directly {
          color: var(--text-color-danger);
          text-align: center;
          width: 100%;
          font-weight: bold;
        }
        label {
          font-size: 15px;
        }
        span {
          width: 163px;
          margin-left: auto;
          display: inline-block;
          border-bottom: 1px solid #000000;
          text-align: center;
        }
        &.total-cost {
          span {
            color: var(--text-color-danger) !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .registration-details {
    .checkbox-block {
      .checkbox-wrapper {
        width: 48%;
        &:nth-child(2n) {
          margin-left: 4%;
        }
      }
    }
    .radio-field {
      margin-bottom: 15px;
    }
    .table-block {
      display: block;
      .table {
        width: 100%;
        margin-bottom: 5px;
      }
      .order-details {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}
