.checkbox-field {
  display: flex;
  align-items: center;
  border: 1px solid;
  padding: 0 5px;
  min-height: 40px;
  border-radius: var(--input-border-radius);
  &.disabled {
    background: var(--background-color-gray);
  }
  label {
    display: flex;
    .label-input {
      color: var(--text-disabled-color);
      input {
        margin: 0 5px;
        width: unset !important;
      }
    }
    .required-field {
      color: var(--text-color-danger);
      margin-right: 5px;
    }
  }
  .checkbox {
    border-radius: var(--input-border-radius);
    font-size: var(--input-font-size);
    border: none;
    outline: none;
    background: none;
    color: var(--text-color-dark);
    padding: var(--input-padding);
    margin-right: 5px;
    position: relative;
    min-width: 15px;
    min-height: 15px;
    &.error {
      box-shadow: 0 0 0 2px var(--input-border-error-color) inset;
    }
    &.flat {
      border-bottom: 1px solid
    }
    &.outline {
      border: 1px solid;
    }
    &.disabled {
      color: var(--disabled-input-color);
      background: var(--background-color-gray);
      border-color: var(--disabled-input-border-color);
    }
  }
}
