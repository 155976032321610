.ucr-modal-wrapper {
  position: fixed;
  display: flex;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
  .ucr-modal {
    background: var(--modal-background-light);
    z-index: 999;
    width: 100%;
    max-width: 80%;
    margin: auto;

    .modal-title {
      background: var(--background-color-dark);
      padding: 5px;
      display: flex;
      min-height: 53px;
      align-items: center;
      color: var(--text-color-light);

      .title {
        width: 100%;
        text-align: center;
      }
      .close {
        svg {
          font-size: 20px;
        }
      }
    }
    .modal-content {
      min-height: 300px;
      padding: 30px 100px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ucr-modal-wrapper {
    .modal-content {
      padding: 15px 20px !important;
    }
  }
}
