.ucr-pricing {
  padding-bottom: 50px;
  .pricing-block {
    width: 50%;
  }
}

@media only screen and (max-width: 600px){
  .ucr-pricing {
    .container {
      .page-title {
        margin-bottom: 0;
      }
      >.flex {
        display: block;
        .text-block {
          padding-top: 0;
        }
        .pricing-block {
          width: 100%;
        }
      }
    }
  }
}
