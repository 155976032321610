.drop-down {
  position: relative;
  z-index: 10;
  .drop-down-list {
    left: 0;
    top: 100%;
    display: none;
    padding: 10px;
    min-width: 175px;
    position: absolute;
    background: var(--background-color-dark);
    border-bottom-left-radius: var(--input-border-radius);
    border-bottom-right-radius: var(--input-border-radius);
    .drop-down-list-item {
      border-bottom: 1px solid var(--text-color-danger);
      a {
        margin: 10px 0;
        display: block;
        font-size: 14px !important;
      }
      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }
    }
  }
  &:hover {
    .drop-down-list {
      display: block;
    }
  }
}
