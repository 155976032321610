.ucr-registration-form {
  padding: var(--modal-padding);
  box-shadow: var(--modal-box-shadow);
  background: var(--modal-background-light);
  border-radius: var(--modal-border-radius);
  padding-bottom: 70px;
  .title {
    text-align: center;
    margin-top: 30px;
    color: var(--text-color-dark);
  }
  .sub-title {
    text-align: center;
    font-weight: 100;
  }
  .input-field {
    margin-top: 30px;
    input {
      text-align: center;
    }
  }
  .checkbox-field {
    border: none;
    justify-content: center;
  }
  .text-center {
    margin: 10px;
  }
  .privacy-block {
    width: 100%;
    display: flex;
    margin-top: 50px;
    margin-bottom: 10px;
    .btn {
      text-decoration: none;
      color: var(--text-color-dark);
    }
    >:last-of-type {
      margin-left: auto;
    }
  }
}
