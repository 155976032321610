.address-row {
  display: flex;

  .input-field {
    width: 33% !important;
    margin-left: 3% !important;

    &.select-field, &:last-child {
      max-width: 15%;
      select {
        width: 100%;
      }
    }

    &:last-child {
      max-width: 10%;
      select {
        width: 100%;
      }
    }

    &:first-child {
      margin-left: 0 !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .address-row {
    display: block;
    .input-field {
      width: 100% !important;
      max-width: 100% !important;
      &:first-child {
        width: 100% !important;
      }
      .select {
        width: 100% !important;
      }
    }
  }
  .read-more-block {
    display: block;
  }
}
