@import "colors";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.container-fluid {
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
  &.flex-end {
    justify-content: flex-end;
  }
}

button {
  transition: all 0.2s;
  &:hover{
    box-shadow: inset 0 0 100px 100px rgba(0, 0, 0, 0.1);
  }
}

.desktop {
  display: block;
}
.mobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .mobile {
    display: block;
  }
  .desktop {
    display: none;
  }
}

.loading-container {
  position: relative;
}

#__react-alert__ {
  > div {
    > div {
      > div {
        background: var(--background-color-dark) !important;
      }
    }
  }
}
