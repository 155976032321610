.text-with-title {
  padding: 20px;
  text-align: center;
  font-size: 14px;
  .page-title {
    margin: 10px 0;
    font-size: inherit;
    font-weight: bold;
  }
}
