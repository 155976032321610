.pricing-block {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  .pricing-table {
    margin: auto;
    width: 100%;
    max-width: 400px;
    border-radius: var(--modal-border-radius);
    overflow: hidden;
    border: 2px solid var(--modal-border-dark);
    .pricing-title {
      padding: 23px;
      font-size: 14px;
      text-align: center;
      color: var(--text-color-light);
      background: var(--background-color-dark);
    }
    .price-list {
      width: 70%;
      margin: 50px auto 20px auto;
      .price-list-item {
        display: flex;
        margin-bottom: 10px;
        justify-content: space-between;
        border-bottom: 1px dashed var(--modal-border-dark);
        .price {
          a {
            text-decoration: none;
            color: var(--text-color-danger);
          }
        }
      }
    }
    .price-footer {
      text-align: center;
      padding-bottom: 20px;
    }
  }
}
