.company-required {
  .company-required-row {
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--disabled-input-border-color);
    .page-title {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}
