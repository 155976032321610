.about-ucr-registration {
  .with-background {
    background-image: url("../../../scss/img/man-bg.png");
    background-repeat: no-repeat;
    background-size: 55%;
    padding-bottom: 25px;
  }
  .text-block {
    border-color: var(--modal-border-dark);
    p {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .about-ucr-registration {
    .with-background {
      padding-top: 70%;
      background-size: 100%;
    }
  }
}
