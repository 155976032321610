.body {
  min-height: calc(100vh - 343px);
}


@media only screen and (max-width: 600px) {
  .body {
    min-height: calc(100vh - 100px);
  }
}
