.page-title {
  margin: 50px auto;
  text-align: center;
  color: var(--text-color-dark);
  font-size: var(--page-title-font-size);
  &.danger {
    color: var(--text-color-danger);
  }
}

@media only screen and (max-width: 600px) {
  .page-title {
    margin-top: 30px;
  }
}
