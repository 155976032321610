.ucr-accordion {
  .ucr-accordion-box {
    margin-bottom: 20px;
    .ucr-accordion-title {
      button  {
        border: none;
        background: none;
        font-size: 18px;
        cursor: pointer;
        text-align: left;
        text-decoration: underline;
        color: var(--text-color-dark);
        &:hover {
          color: var(--text-color-danger);
          box-shadow: none;
        }
      }
    }
    .ucr-accordion-content {
      max-height: 0;
      overflow: hidden;
      transition: .15s ease-in-out;
      background: var(--background-color-neutral);
    }
    &.active {
      .ucr-accordion-content {
        padding: 15px;
        margin-top: 10px;
        max-height: 300px;
        overflow-y: auto;
      }
    }
  }
}
