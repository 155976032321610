.about-registration {
  .text-block {
    margin-top: 20px;
    width: 50%;
    border-width: 2px;
    border-style: solid;
    padding: var(--modal-padding);
    border-radius: var(--modal-border-radius);
    background: var(--modal-background-light);
    p {
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .about-registration {
    .text-block {
      width: 100%;
      border: none;
    }
  }
}
