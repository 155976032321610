.registration-page {
  padding: var(--paddining-container);
  .content-with-stepper {
    display: flex;
    .stepper-side {
      width: 290px;
      padding: 20px 0 50px 0;
      min-height: 400px;
      box-shadow: var(--side-bar-box-shadow);
      border-radius: var(--side-bar-border-radius);
      .us-dot {
        text-align: center;
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 20px;
        color: var(--text-color-danger);
      }
    }
    .stepper-content {
      width: 100%;
      padding: 0 20px 20px 20px;
      margin-left: 20px;
      box-shadow: var(--side-bar-box-shadow);
      border-radius: var(--side-bar-border-radius);
      >.page-title {
        margin: 10px;
        &.msc-info {
          display: flex;
          font-size: 16px;
          font-weight: bold;
          align-items: center;
          padding-bottom: 8px;
          justify-content: center;
          border-bottom: 1px solid var(--border-color-dark);
          .btn {
            margin-left: 5px;
            padding: 3px 15px;
            font-size: 13px;
          }
        }
      }
      .input-field {
        width: 48%;
        display: inline-block;
        margin-bottom: 40px;
        &:nth-child(2n) {
          margin-left: 4%;
        }
        input {
          width: 100%;
        }
        &.full {
          width: 100%;
          margin-left: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .registration-page {
    padding-left: 0;
    padding-right: 0;
    .input-field {
      width: 100% !important;
      margin: 0 !important;
    }
    .content-with-stepper {
      display: block;
      .stepper-content {
        padding: 10px;
        margin: 10px 0 0 0;
      }
      .stepper-side {
        width: 100%;
        padding: 0;
        background: none;
        box-shadow: none;
        min-height: 0;
        .us-dot {
          margin-bottom: 20px;
        }
        .ucr-stepper {
          display: flex;
          padding: 0;
          .ucr-step-block {
            .ucr-step {
              display: block;
              text-align: center;
              .ucr-step-text-block {
                padding: 0;
                .ucr-step-title {
                  font-size: 15px;
                }
              }
            }
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
}
