.radio-block {
  display: flex;
  .radio-field {
    &~.radio-field {
      margin-left: 15px;
    }
  }
}
.radio-field {
  display: flex;
  align-items: center;
  label {
    display: flex;
    margin-bottom: 12px;
    .label-input {
      color: var(--text-disabled-color);
      input {
        margin: 0 5px;
        width: unset !important;
      }
    }
    .required-field {
      color: var(--text-color-danger);
      margin-right: 5px;
    }
  }
  .radio {
    border-radius: var(--input-border-radius);
    font-size: var(--input-font-size);
    border: none;
    outline: none;
    background: none;
    color: var(--text-color-dark);
    min-height: 40px;
    margin-bottom: 12px;
    padding: var(--input-padding);
    margin-right: 5px;
    &.error {
      border-color: var(--input-border-error-color) !important;
    }
    &.flat {
      border-bottom: 1px solid
    }
    &.outline {
      border: 1px solid;
    }
    &.disabled {
      color: var(--disabled-input-color);
      background: var(--background-color-gray);
      border-color: var(--disabled-input-border-color);
    }
  }
}
