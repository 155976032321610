.ucr-step-block {
  .ucr-step {
    display: flex;
    .ucr-step-icon {
      svg {
        color: var(--text-disabled-color);
        font-size: 30px;
      }
    }
    .ucr-step-text-block {
      padding-left: var(--input-padding);
      .ucr-step-title{
        color: var(--text-disabled-color);
      }
      .ucr-step-description {
        font-size: 10px;
        color: var(--text-disabled-color);
      }
    }

    &.active {
      .ucr-step-icon {
        svg {
          color: var(--text-color-dark);
        }
      }
      .ucr-step-text-block {
        .ucr-step-title{
          color: var(--text-color-dark);
        }
      }
    }
  }
  &:after {
    content: "";
    height: 100px;
    margin-left: 15px;
    margin-bottom: 5px;
    display: inline-block;
    border: 1px solid var(--text-disabled-color);
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}

