.stepper-footer {
  gap: 30px;
  display: flex;
  margin-top: 40px;
  align-items: center;
  justify-content: flex-end;
  button {
    min-width: 200px;
    height: 36px;
    &.previous {
      background: var(--btn-background-color-gray);
    }
  }
  .ucr-captcha {
    margin: 0 auto 0 0;
  }
}
@media only screen and (max-width: 600px) {
  .stepper-footer {
    flex-wrap: wrap;
    .ucr-captcha {
      margin: 0 auto 15px auto;
    }
    justify-content: space-around;
    gap: unset;
    button {
      min-width: 140px;
      flex: 1;
    }
  }
}
