.header {
  background: var(--background-color-dark);
  color: var(--text-color-light);
  .container {
    display: flex;
    min-height: 85px;
    justify-content: space-between;
    .nav-bar {
      width: 100%;
      display: flex;
      align-items: center;
      ul {
        display: flex;
        align-items: center;
        width: 100%;
        li {
          list-style: none;
          padding: 0 20px;
          a {
            color: inherit;
            transition: all 0.1s;
            text-decoration: none;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            font-family: "Inter";
            img {
              max-height: 50px;
            }
            &:hover {
              color: var(--text-hover-color-light);
            }
          }
          &.active {
            a {
              color: var(--text-hover-color-light);
            }
          }
          &:first-child {
            margin-right: 50px;
          }
          &:last-child {
            margin-left: auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    z-index: 5;
    position: relative;
    img {
      max-height: 50px;
    }
    .container {
      position: relative;
      min-height: unset;
      padding: 4px 10px;
      display: flex;
      justify-content: space-between;
      .icon-with-info {
        .info {
          margin-left: 3px;
        }
      }
      .toggle {
        border: none;
        font-size: 30px;
        background: transparent;
        box-shadow: none;
        color: var(--text-color-light);
      }
      .logo-mobile {
        width: calc(100% - 212px);
      }
      .nav-bar {
        position: absolute;
        left:0;
        top: 100%;
        justify-content: flex-end;
        ul {
          top: 100%;
          right: 0;
          z-index: 5;
          width: 0;
          overflow: hidden;
          text-align: right;
          background: var(--mobile-menu-item-background) !important;
          transition: 0.2s;
          display: block;
          &.open {
            width: 100%;
          }
          li {
            &:first-child {
              display: none;
            }
            padding: 6px;
            display: block;
            margin-bottom: 6px;
            margin-right: unset !important;
            border-bottom: 1px solid var(--border-color-dark);
            text-align: left;
            a {
              white-space: nowrap;
              color: var(--mobile-menu-item-color);
            }
          }
        }
      }
    }
  }
}
