.registration-section {
  position: relative;
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-image: url("../../../scss/img/ucr-registration-bg.jpeg");
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
  }
  .container {
    z-index: 2;
    position: relative;
    display: flex;
    padding-top: 81px;
    padding-bottom: 265px;
    >.flex-item {
      flex: 1;
    }
  }
}

@media only screen and (max-width: 600px) {
  .registration-section {
    background-position-x: 66%;
    .container {
      display: block;
      >.flex-item {
        max-width: 400px;
        margin: 0 auto;
        .registration-help-text {
          margin: 10px auto 40px auto;
        }
      }
    }
  }
}
