:root {
  --loading-size: 50px;
  --text-color-light: #ffffff;
  --text-color-dark: #04365B;
  --text-color-neutral : #418688;
  --text-disabled-color: #84858a;
  --text-color-danger: #B70C18;
  --background-color-dark: #04365B;
  --background-color-neutral: rgba(4, 54, 91, 0.2);
  --background-color-danger: #B70C18;
  --background-color-gray: #e4e6ed;
  --disabled-input-color: #000000;
  --disabled-input-border-color: #e4e6ed;
  --text-hover-color-light: #B70C18;
  --mobile-menu-item-background: rgb(164, 168, 179);
  --mobile-menu-item-color: #000000;

  --modal-background-light: #ffffff;
  --modal-background-dark: linear-gradient(90.24deg, rgba(255, 255, 255, 0.2) 0%, rgba(47, 45, 46, 0.06) 100%);
  --modal-box-shadow: 0px 0px 25px 15px rgba(0, 0, 0, 0.25);
  --modal-border-radius: 15px;
  --modal-padding: 25px;
  --modal-big-padding: 25px 40px;
  --modal-border-dark: #04365B;
  --big-container-padding: 0 25px;
  --paddining-container: 25px;

  --border-color-dark: #04365B;
  --btn-border-radius: 5px;
  --btn-padding: 10px;
  --btn-font-size: 14px;
  --btn-background-color-gray: #8f929b;

  --page-title-font-size: 20px;

  --input-border-radius: 5px;
  --input-padding: 5px;
  --input-color: rgba( 0, 0, 0, 0.7 );
  --input-border-color: rgba( 0, 0, 0, 0.25 );
  --input-border-error-color: #B70C18;
  --input-background: #ffffff;
  --input-disabled-background: #f5f5f5;

  --side-bar-background: rgba(164, 168, 179, 0.08);
  --side-bar-box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  --side-bar-border-radius: 10px;
}
