.footer {
  background: #04365B;
  color: var(--text-color-light);
  .container {
    display: flex;
    padding-top: 20px;
    .text-title {
      margin-bottom: 25px;
    }
    .flex-item {
      width: 100%;
      padding: 20px;
      .icon-with-info {
        margin-bottom: 15px;
        .info {
          font-size: 14px;
          a {
            color: inherit;
            text-decoration: none;
          }
        }
      }
      &.about-ucr {
        padding-right: 55px;
        box-sizing: border-box;
        p {
          font-size: 12px;
        }
      }
      &.quick-links {
        ul {
          li {
            a {
              display: block;
              font-size: 12px;
              color: inherit;
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .footer {
    .container {
      padding-top: 0;
      .flex-item {
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        padding-right: 0;
        .icon-with-info {
          margin-bottom: 0;
          font-size: 11px;
          svg {
            font-size: inherit;
          }
          .info {
            margin-left: 5px;
            font-size: inherit;
          }
        }
      }
      .about-ucr,
      .quick-links,
      .text-title {
        display: none;
      }
    }
  }
}
