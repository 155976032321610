.required-field-message {
  color: var(--text-color-danger);
}
.group-input-label {
  display: flex;
  margin-bottom: 12px;
  &.error {
    color: var(--text-color-danger);
  }
  .required {
    color: var(--text-color-danger);
    margin-right: 5px;
  }
  .label-input {
    color: var(--text-disabled-color);
    font-size: 13px;
    display: flex;
    align-items: center;
    input {
      margin: 0 5px;
      min-height: 15px;
      min-width: 15px;
    }
  }
}
.input-field {
  label {
    display: flex;
    margin-bottom: 12px;
    .label-input {
      font-size: 12px;
      color: var(--text-disabled-color);
      display: flex;
      align-items: center;
      input {
        margin: 0 5px;
        width: unset !important;
        background: var(--input-background);
        color: var(--input-color);
        border: 1px solid var(--input-border-color);
      }
    }
    .required-field {
      color: var(--text-color-danger);
      margin-right: 5px;
    }
  }
  .input, .textarea, .select, .radio {
    opacity: 1;
    border: none;
    height: 40px;
    outline: none;
    background: none;
    margin-bottom: 12px;
    color: var(--input-color);
    padding: var(--input-padding);
    font-size: var(--input-font-size);
    border-radius: var(--input-border-radius);
    &.error {
      border-color: var(--input-border-error-color) !important;
    }
    &.full-width {
      width: 100%;
    }
    &.flat {
      border-bottom: 1px solid var(--input-border-color);
      border-radius: 0;
    }
    &.outline {
      border: 1px solid var(--input-border-color);
    }
    &.disabled {
      background: var(--input-disabled-background);
    }
  }
  &.radio-field {
    display: flex !important;
    justify-content: flex-start !important;
    label {
      display: inline-block;
    }
    input.radio {
      width: unset !important;
    }
  }
  textarea {
    min-height: 110px !important;
  }
}
