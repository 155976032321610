.carrier-info-step {
  .read-more-block {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--border-color-dark);
    .checkbox-field {
      border: none !important;
      width: unset;
    }
    label {
      font-size: 13px;
    }
    .btn {
      background: none;
      margin-left: 10px;
      min-width: 85px;
    }
  }
  .creator-input {
    .input-field {
      margin-bottom: 0 !important;
      input {
        margin-bottom: 0 !important;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .read-more-block {
    display: block;
  }
}
