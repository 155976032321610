.states-map {
  .us-state-map {
    width: 100%;
    height: 100%;
    .state {
      transition: 0.5ms;
      &:hover {
        fill: var(--background-color-danger);
      }
    }
  }
}
