.registration-help-text {
  max-width: 328px;
  background: #0000003d;
  color: var(--text-color-light);
  padding: var(--modal-big-padding);
  margin-top: 30px;
  border-radius: var(--modal-border-radius);
  &:first-child {
    margin-top: 40%;
  }
  a {
    display: block;
    text-align: center;
  }
  .btn {
    margin-top: 10px;
  }
}
