.select-field {
  position:relative;
  display: inline;
  .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    color: var(--input-color);
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    bottom: 0;
    margin-bottom: 27px;
    right: 5px;
    border-top: 8px solid black;
    opacity: 0.5;
  }
}
