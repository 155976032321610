.map-container-section {
  .container {
    .flex {
      display: flex;
      .map-block {
        width: 60%;
      }
      &.flex-end {
        justify-content: flex-end;
        >div {
          width: 40%;
          .icon-with-info {
            margin-top: 70px;
            .info {
              font-size: 16px;
            }
          }
        }
      }
      &.form-block {
        margin-top: -50px;
        padding-bottom: 50px;
        >div {
          width: 50%;
        }
        .text-column {
          padding: 100px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .map-container-section {
    .container {
      >.flex {
        display: block;
        > div {
          display: flex;
          width: 100% !important;
          justify-content: space-between;
          padding: 0;
        }
        .map-block {
          margin-bottom: 20px;
        }
        &.form-block {
          padding: 0;
          margin-top: 30px !important;
          .text-column {
            display: block;
            padding: 0;
            margin-top: 30px;
            .page-title {
              max-width: 100%;
            }
          }
          .form-column {
            margin-top: 0;
            padding: 0;
            .pricing-block {
              width: 100%;
              margin-bottom: 30px;
            }
          }
        }
      }
    }
  }
}
