.success-page {
  .content-block {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    svg {
      font-size: 50px;
      margin-bottom: 30px;
      color: var(--text-color-neutral);
    }
  }
  .footer-block {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .success-page {
    .content-block {
      display: block;
    }
  }
}
