.btn {
  border-radius: var(--btn-border-radius);
  padding: var(--btn-padding);
  font-size: var(--btn-font-size);
  cursor: pointer;
  border: none;
  &.full-width {
    width: 100%;
  }
  &.solid {
    background: var(--background-color-dark);
    color: var(--text-color-light);
    &.danger {
      background: var(--background-color-danger);
    }
    &.disabled {
      background: var(--text-disabled-color);
    }
  }
  &.flat {
    color: var(--text-color-dark);
    background: transparent;
  }

  &.link {
    padding: 0;
    border: none;
    background: none;
    text-decoration: underline;
    box-shadow: none;
    color: var(--text-color-danger);
  }
  &.outline {
    border: 1px solid var();
  }
}
