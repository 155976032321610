.option-modal-content {
  p {
    margin-top: 20px;
    &.center {
      text-align: center;
    }
  }
  h3 {
    margin-top: 20px;
    text-align: center;
    color: var(--text-color-dark);
  }
  .option-modal-footer {
    gap: 30px;
    display: flex;
    margin-top: 40px;
    justify-content: center;
    .btn {
      width: 100%;
      max-width: 200px;
    }
  }
}
