.select-year-row {
  display: flex;
  align-items: end;
  width: 48%;
  .input-field {
    margin-bottom: 0 !important;
    width: 100% !important;
    max-width: calc(100% - 195px);
  }
  .btn {
    height: 40px;
    margin-bottom: 12px;
    margin-left: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .select-year-row {
    width: 100%;
  }
}
