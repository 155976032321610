.contact-us-form {
  margin: 0 auto;
  max-width: 475px;
  padding: 60px 40px;
  background: var(--modal-background-light);
  border-radius: var(--modal-border-radius);
  border: 1px solid var(--modal-border-dark);
  .select-field {
    &:after {
      margin-bottom: 5px;
    }
    .select {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .contact-us-form {
    width: 100%;
    margin-bottom: 50px;
  }
}
