.info-pages {
  padding-bottom: 50px;
  h3 {
    margin-top: 50px;
    padding-top: 10px;
    margin-bottom: 10px;
    color: var(--text-color-dark);
    border-top: 1px solid var(--border-color-dark);
  }
  p {
    margin-bottom: 15px;
    &.page-title {
      margin-bottom: 50px;
    }
  }
  .important {
    font-weight: bold;
    color: var(--text-color-danger);
  }
}
