.signature-modal {
  .signature-modal-content {
    text-align: center;
    .signature-wrapper {
      width: 100%;
      height: 50vw;
      max-height: 300px;
      border: 1px solid;
      position: relative;
      border-radius: var(--input-border-radius);
      img {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
      }
    }
    .signature-modal-footer {
      text-align: center;
      margin-top: 20px;
      .signature-canvas {
        border: 1px solid;
        border-radius: var(--input-border-radius);
      }
      .btn {
        width: 42%;
        margin: 0 10px;
        max-width: 200px;
      }
    }
  }
}
